import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl, finagApiUrl } from "../config";
import cogoToast from "cogo-toast";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const refreshToken = createAsyncThunk(
  "refreshToken",
  async (refreshToken, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${finagApiUrl}auth-web-login/refresh/`, {
        refresh: refreshToken,
      });

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // Handle token refresh error
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const userLogin = createAsyncThunk(
  "login",
  async ({ username, password }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${apiUrl}auth/signin`, {
        username: username,
        password,
      });
      if (res.status === 200) {
        // let role = res.data.user.is_sales_agent
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        cogoToast.error("Please check that the credentials entered is valid!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "forgot",
  async ({ email }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${apiUrl}auth/forgot-password`, {
        email: email,
      });
      if (res.status === 200) {
        cogoToast.success(res.data.message);
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("Please check that the credentials entered is valid!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const resetPassword = createAsyncThunk(
  "reset",
  async ({ otp, userId, password }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${apiUrl}auth/reset-password`, {
        otp,
        user_id: userId,
        password: password,
      });
      if (res.status === 200) {
        cogoToast.success("Password successfully changed, Login to continue", {
          position: "top-right",
        });
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error(
          `The link is either invalid or has expired, please request again!`
        );
        return rejectWithValue(error.response.data);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        cogoToast.error(
          `The link is either invalid or has expired, please request again!`
        );
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const changePassword = createAsyncThunk(
  "changePassword",
  async ({ old_password, new_password }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}auth/change-password`,
        {
          old_password: old_password,
          new_password: new_password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        cogoToast.success("Password successfully changed, Login to continue", {
          position: "top-right",
        });
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("Check that your old password is correct!");
        return rejectWithValue(error.response.data);
      } else {
        cogoToast.error("Check that your old password is correct!");
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const updateUserDetails = createAsyncThunk(
  "updateUserDetails",
  async ({ first_name, last_name }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.put(
        `${apiUrl}users`,
        {
          first_name: first_name,
          last_name: last_name,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        cogoToast.success("Profile details updated successfully!", {
          position: "top-right",
        });
        return {
          data: res.data,
          firstname: first_name,
          lastname: last_name,
        };
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("Please check that the credentials entered is valid!");
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getTeammatesByStatus = createAsyncThunk(
  "getTeammatesByStatus",
  async ({ page, status }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${finagApiUrl}sharp/users?status=${status}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const verifyInviteLink = createAsyncThunk(
  "verifyInviteLink",
  async ({ code, id }, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${finagApiUrl}sharp/invite/verify?code=${code}&id=${id}`
      );

      if (res.status === 400) {
        return rejectWithValue(res.data.errors);
      }

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error?.message || "Unexpected error");
    }
  }
);

export const getDepartments = createAsyncThunk(
  "getDepartments",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${finagApiUrl}sharp/departments`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getRolesByDepartment = createAsyncThunk(
  "getRolesByDepartment",
  async ({id}, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${finagApiUrl}sharp/roles?department_id=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

// Invite teammate to sharp
export const InviteTeammate = createAsyncThunk(
  "InviteTeammate",
  async ({ email,role, department_id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${finagApiUrl}sharp/invite/`,
        { email: email, role: role, department_id: department_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return cogoToast.success("Invite link has been sent successfully!");
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      }
      if (error.response.status === 500) {
        cogoToast.error("Oops, looks like an error occurred inviting this user, Please try again!");
        return rejectWithValue(error.response.data);
      }
      if(error.response.status === 403){
        cogoToast.error(`${error.response.data.errors}`);
        return rejectWithValue(error.response.data);
      } 
      else {
        cogoToast.error("Something went wrong");
        return rejectWithValue(error.response.data);
      }
    }
  }
);

// Resend Invite link
export const resendInviteLink = createAsyncThunk(
  "resendInviteLink",
  async ({ email,role, department_id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${finagApiUrl}sharp/invite/resend/`,
        { email: email, role: role, department_id: department_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return cogoToast.success("Invite link has been sent successfully!");
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      }
      if (error.response.status === 500) {
        cogoToast.error("Oops, looks like an error occurred resending this invite, Please try again!");
        return rejectWithValue(error.response.data);
      } else {
        cogoToast.error("Something went wrong");
        return rejectWithValue(error.response.data);
      }
    }
  }
);

// Cancel Invite
export const CancelInvite = createAsyncThunk(
  "CancelInvite",
  async ({ email, department_id, role }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.patch(
        `${finagApiUrl}sharp/invite/cancel/`,
        { 
          email: email,
          role: role,
          department_id: department_id 
         },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return cogoToast.success("Your invite has been cancelled successfully!");
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      }
      if (error.response.status === 500) {
        cogoToast.error("Oops, looks like an error occurred cancelling this invite, Please try again!");
        return rejectWithValue(error.response.data);
      }
      if(error.response.status === 403){
        cogoToast.error(`${error.response.data.errors}`);
        return rejectWithValue(error.response.data);
      } 
      else {
        cogoToast.error(`${error.response.data.errors}`);
        return rejectWithValue(error.response.data);
      }
    }
  }
);

// Deactivate a teammate
export const DeactivateTeammate = createAsyncThunk(
  "DeactivateTeammate",
  async ({ email }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.patch(
        `${finagApiUrl}sharp/invite/remove/`,
        { 
          email: email, 
         },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return cogoToast.success("Your account has been successfully deactivated!");
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      }
      if (error.response.status === 500) {
        cogoToast.error("Oops, looks like an error occurred deactivating this teammate!");
        return rejectWithValue(error.response.data);
      }
      if(error.response.status === 403){
        cogoToast.error(`${error.response.data.errors}`);
        return rejectWithValue(error.response.data);
      } 
      else {
        cogoToast.error(`${error.response.data.errors}`);
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const createProfile = createAsyncThunk(
  "createProfile",
  async (userData, { rejectWithValue }) => {
    console.log(userData);
    try {
      const res = await axios.post(`${finagApiUrl}sharp/invite/profile/`, {
        first_name: userData.firstname,
        last_name: userData.lastname,
        password: userData.password,
        email: userData.email,
        username: userData.username,
        code: userData.code,
        id: userData.id,
      });

      console.log(res);

      if (res.status === 201) {
        cogoToast.success("Profile was created Successfully");
      }

      return res.data;
    } catch (error) {
      if (error.response.status === 401) {
        cogoToast.error(error.errors);
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);
