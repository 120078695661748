import React, { useCallback, useEffect, useState } from "react";
import GoBack from "../../components/GoBack";
import Header from "../../components/Header";
import { useDropzone } from "react-dropzone";
import {
  AddCircleOutline,
  AddPhotoAlternateOutlined,
  Close,
  ExpandMore,
} from "@mui/icons-material";
import { useParams } from "react-router-dom";
import StoreModal from "../../containers/inventory/storeModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllState } from "../../redux/Location/locationAction";
import Delete from "../../assets/icons/delete.svg";
import VariantModal from "../../containers/inventory/variantModal";
import CropCategoryOne from "../../containers/inventory/cropCategoryOne";
import CropCategoryTwo from "../../containers/inventory/cropCategoryTwo";
import CropCategoryThree from "../../containers/inventory/cropCategoryThree";
import CropCategoryFour from "../../containers/inventory/cropCategoryFour";
import CropCategoryFive from "../../containers/inventory/cropCategoryFive";

const AddProduct = () => {
  const { type } = useParams();
  const dispatch = useDispatch();
  const [file, setFile] = useState([]);
  const [categoryOneData, setCategoryOneData] = useState({
    vendor: "",
    productClass: "",
    doseRate: "",
    applicationTime: "",
    targetCrops: "",
    mixingAndApplication: "",
    activeIngredients: "",
  });
  const [categoryTwoData, setCategoryTwoData] = useState({
    vendor: "",
    productClass: "",
    doseRate: "",
    targetCrops: "",
    mixingAndApplication: "",
    activeIngredients: "",
  });

  const [categoryThreeData, setCategoryThreeData] = useState({
    vendor: "",
    doseRate: "",
    rateOfUse: "",
    composition: "",
    targetCrops: "",
    modeOfAction: "",
  });

  const [categoryFourData, setCategoryFourData] = useState({
    vendor: "",
    doseRate: "",
    targetCrops: "",
    mixingAndApplication: "",
    activeIngredients: "",
  });

  const [categoryFiveData, setCategoryFiveData] = useState({
    vendor: "",
    seedRate: "",
    adaptability: "",
    requirements: "",
  });

  const onDrop = useCallback((acceptedFiles) => {
    setFile((prevFile) => [...prevFile, acceptedFiles]);
  }, []);

  const [isStoreModalOpen, setIsStoreModalOpen] = useState(false);
  const [isVariantModalOpen, setIsVariantModalOpen] = useState(false);
  const [isStoreVariantOpen, setIsStoreVariantOpen] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState({});
  const [selectedVariant, setSelectedVariant] = useState({});
  const [selectedState, setSelectedState] = useState([]);
  const [selectedSize, setSelectedSize] = useState([
    {
      unit: "kg (Kilogram)",
      number: 0,
    },
  ]);
  const [isClickState, setIsClickState] = useState(false);
  const [productName, setProductName] = useState("");
  const [save, setSave] = useState(false);
  const [saveSize, setSaveSize] = useState(false);
  const [variant, setVariant] = useState(false);
  const [toggleUnit, setToggleUnit] = useState(false);

  const { stateData } = useSelector((state) => state.location);
  const handleStateClick = () => {
    setIsClickState(!isClickState);
    dispatch(getAllState());
  };

  const handleEnterSize = (event) => {
    if (event.key === "Enter") {
      setSelectedSize((prev) => [
        ...prev,
        {
          unit: "kg (Kilogram)",
          number: 0,
        },
      ]);
    }
  };

  const handleSelectedState = (id, name) => {
    if (selectedState.some((data) => data.id === id)) {
      // eslint-disable-next-line
      setSelectedState(selectedState.filter((each) => each.id != id));
      return;
    }
    setSelectedState((selServices) => [...selServices, { name: name, id: id }]);
  };

  const [stores, setStores] = useState([
    {
      id: 1,
      name: "Crop2cash Ibadan",
      quantity: 23,
    },
    {
      id: 2,
      name: "North Warehouse",
      quantity: 2,
    },
  ]);

  const units = [
    {
      id: 1,
      name: "kg (Kilogram)",
    },
    {
      id: 2,
      name: "g (Gram)",
    },
    {
      id: 3,
      name: "mg (Milligram)",
    },

    {
      id: 4,
      name: "L (Litre)",
    },

    {
      id: 5,
      name: "ml (Millilitre)",
    },
    {
      id: 6,
      name: "cl (Centilitre)",
    },
    {
      id: 7,
      name: "gal (Gallon)",
    },
  ];

  const [selectedTab, setSelectedTab] = useState(stores[0]?.name);

  const [variants] = useState([
    {
      id: 1,
      name: "State",
    },
    {
      id: 2,
      name: "Size",
    },
    {
      id: 3,
      name: "Application Method",
    },
  ]);

  const [status] = useState([
    {
      id: 1,
      name: "Active",
    },
    {
      id: 2,
      name: "Inactive",
    },
  ]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });
  let lastStore = stores[stores.length - 1];

  const handleQuantity = (e, id) => {
    const newQuantity = Number(e.target.value);
    setStores((prevStores) =>
      prevStores.map((store) =>
        store.id === id ? { ...store, quantity: newQuantity } : store
      )
    );
  };

  const handleSelectedStatus = (status) => {
    setIsStatusOpen(false);
    setSelectedStatus(status);
  };

  const [imageIndex, setImageIndex] = useState(null);

  const handleRemoveFile = () => {};

  const [formattedArray, setFormattedArray] = useState([]);

  useEffect(() => {
    const formattedData = selectedState.flatMap((state) =>
      selectedSize.map((size) => ({
        ...state,
        ...size, // Merging size data directly
      }))
    );
    setFormattedArray(formattedData);
  }, [selectedSize, selectedState]);

  console.log(formattedArray);

  return (
    <div>
      <GoBack />

      <div className="mt-3">
        <Header
          containerStyle="border border-[#E1E6E1] py-3"
          style={{ color: "#2E332F" }}
          text={`Add Product (${type})`}
        >
          <div className="flex gap-6 items-center">
            <p className="text-[#5C715E] font-medium cursor-pointer text-sm leading-5 px-3">
              Discard
            </p>
            <button
              disabled={true}
              className="outline-none cursor-pointer bg-primary border disabled:bg-[#ABB6AC] disabled:border-[#ABB6AC] border-primary text-white font-medium text-sm leading-5 py-[10px] px-[20px] rounded-[8px]  hover:border hover:border-primary  transition ease-in-out delay-100"
            >
              Save Changes
            </button>
          </div>
        </Header>
      </div>

      <div className="flex gap-2 mt-3">
        <div className="w-[60%]">
          <div className="rounded-lg bg-white p-4 h-fit border border-[#E1E6E1]">
            {/* PRODUCT NAME */}
            <div className="flex flex-col gap-4">
              <div>
                <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                  Product Name
                </p>
                <input
                  type="text"
                  onChange={(event) => setProductName(event.target.value)}
                  placeholder="Product Name"
                  className="outline-none border border-[#96A397] w-full h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
                />
              </div>

              <div>
                <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                  Product Description
                </p>
                <textarea
                  type="text"
                  placeholder="Product Name"
                  className="outline-none border border-[#96A397] resize-none w-full h-[134px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
                />
              </div>

              <div className="flex  pt-3 border-t border-t-[#E1E6E1] gap-[10px]">
                <input
                  type="checkbox"
                  value={variant}
                  onChange={() => setVariant(!variant)}
                  className="w-4 h-4 cursor-pointer accent-primary mt-[2px]"
                />

                <div className="flex flex-col gap-1">
                  <p className="text-[#2E332F] font-semibold text-sm leading-[18px]">
                    Variants
                  </p>

                  <p className="text-[#96A397] font-medium text-sm leading-[18px]">
                    This product has multiple variants
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* PRODUCT IMAGES */}
          <div className="rounded-lg bg-white  h-fit border border-[#E1E6E1] mt-3">
            <div className="flex flex-col gap-4 mb-3">
              <div className="border-b border-b-[#E1E6E1] px-4 py-3 pb-3">
                <p className="text-[#4B564D] font-semibold text-sm">
                  Product Images
                </p>
              </div>
              <div className="px-4">
                <div className=" ">
                  <div>
                    {isDragActive ? (
                      <div className="flex gap-3 flex-wrap items-center justify-center px-3 py-3 cursor-pointer border border-[#E1E6E1] w-full h-[146px]  rounded-lg">
                        <p className="text-[#16461A] font-medium text-sm  text-center">
                          Drop the files here ...
                        </p>
                      </div>
                    ) : (
                      <div>
                        {file.length !== 0 ? (
                          <>
                            <div className="flex gap-3 flex-wrap px-3 py-3 cursor-pointer border border-[#E1E6E1] w-full h-full min-h-[146px]  rounded-lg">
                              {file.map((filedata, index) => {
                                return (
                                  <div
                                    key={index}
                                    onMouseLeave={() => setImageIndex(null)}
                                    onMouseEnter={() => setImageIndex(index)}
                                    className="relative"
                                  >
                                    {imageIndex === index && (
                                      <div className="absolute border border-[#E1E6E1] rounded-full w-5 h-5 flex justify-center items-center top-[-5px] right-[-3px] bg-white ">
                                        <Close
                                          onClick={() => {
                                            handleRemoveFile(filedata, index);
                                          }}
                                          style={{
                                            color: "#4B564D",
                                            fontSize: "12px",
                                          }}
                                        />
                                      </div>
                                    )}

                                    <div className="w-[48px] h-[48px] rounded-lg border border-[#E1E6E1]"></div>
                                  </div>
                                );
                              })}

                              <div
                                {...getRootProps()}
                                className="w-[48px] h-[48px] rounded-lg border border-[#E1E6E1] bg-[#F6F7F6] flex justify-center items-center"
                              >
                                <AddPhotoAlternateOutlined
                                  style={{ fontSize: "24px", color: "#2B8C34" }}
                                />
                                <input {...getInputProps()} />
                              </div>
                            </div>
                          </>
                        ) : (
                          <div
                            {...getRootProps()}
                            className="flex justify-center items-center w-full h-[146px]  border border-[#E1E6E1] rounded-lg"
                          >
                            <div className="flex flex-col justify-center items-center cursor-pointer ">
                              <AddPhotoAlternateOutlined
                                style={{ fontSize: "24px", color: "#2B8C34" }}
                              />
                              <p className="text-[#16461A] font-medium text-sm max-w-[140px] text-center mt-2">
                                Upload a .jpeg or .png file of the product
                              </p>
                              <input {...getInputProps()} />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* PRODUCT PRICING */}
          {!variant && (
            <div className="rounded-lg bg-white h-fit border border-[#E1E6E1] mt-3">
              <div className="flex flex-col gap-4 mb-3">
                <div className="border-b border-b-[#E1E6E1] px-4 py-3 pb-3">
                  <p className="text-[#4B564D] font-semibold text-sm">
                    Product Pricing
                  </p>
                </div>
                <div className="px-4">
                  <div className="flex gap-6">
                    <div>
                      <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                        Price
                      </p>
                      <input
                        type="number"
                        placeholder="₦"
                        className="outline-none border border-[#96A397] w-full h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
                      />
                    </div>

                    <div>
                      <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                        Discount Price
                      </p>
                      <input
                        type="number"
                        placeholder="₦"
                        className="outline-none border border-[#96A397] w-full h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
                      />
                    </div>
                  </div>
                  <div className="border-b border-b-[#E1E6E1] py-2"></div>
                  <div className="flex gap-6 mt-3">
                    <div className="flex-1">
                      <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                        Cost price of the item
                      </p>
                      <input
                        type="number"
                        placeholder="₦"
                        className="outline-none border border-[#96A397] w-full h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
                      />
                    </div>

                    <div className="flex-1">
                      <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                        Profit
                      </p>
                      <input
                        type="number"
                        disabled
                        placeholder="₦"
                        className="outline-none disabled:bg-[#F7F7F7] border border-[#96A397] w-full h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
                      />
                    </div>

                    <div className="flex-1">
                      <p className="text-[#344335] font-normal text-sm leading-[18px] pb-1">
                        Margin
                      </p>
                      <input
                        type="number"
                        disabled
                        placeholder="₦"
                        className="outline-none border border-[#96A397] disabled:bg-[#F7F7F7]  w-full h-[44px] rounded-lg px-3 py-4 placeholder:text-[#96A397] text-sm font-medium leading-5"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Stores */}
          <div className="rounded-lg z-10 relative bg-white h-fit border border-[#E1E6E1] mt-3">
            <div className="flex flex-col">
              <div className="border-b border-b-[#E1E6E1] px-4 py-3">
                <p className="text-[#4B564D] font-semibold text-sm">
                  Inventory
                </p>
              </div>
              <div className="px-4">
                {stores?.map((store, index) => {
                  return (
                    <div
                      key={index}
                      className={`flex justify-between items-center py-4 ${
                        lastStore?.name === store.name
                          ? ""
                          : "border-b border-b-[#EDECE7]"
                      } `}
                    >
                      <p className="text-[#4B564D] font-semibold text-sm">
                        {store.name}
                      </p>
                      <div className="flex border items-center gap-2 rounded-lg  px-3 w-fit">
                        <p className="text-[#4B564D] font-semibold text-sm py-2">
                          Quantity
                        </p>
                        <p className="text-[#4B564D] font-bold text-base py-2">
                          :
                        </p>

                        <input
                          type="text"
                          value={store.quantity}
                          onChange={(e) => handleQuantity(e, store.id)}
                          className="border-none outline-none w-[25px] max-w-[40px] text-[#4B564D] font-semibold text-sm"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                className="border-t border-t-[#E1E6E1] px-4 cursor-pointer flex py-3 gap-1 items-center"
                onClick={() => setIsStoreModalOpen(true)}
              >
                <AddCircleOutline
                  style={{ color: "#2B8C34", fontSize: "18px" }}
                />
                <p className="text-primary font-semibold text-sm">Add Store</p>
              </div>
            </div>
          </div>

          {/* Variants */}
          {variant && (
            <>
              <div className="relative">
                {isStoreVariantOpen && (
                  <div className="absolute transition-all bottom-[50px]  left-[15px] w-[348px] rounded-lg shadow-md border border-[#E1E6E1] mt-[-155px] bg-white padding-[8px] cursor-pointer py-[8px] z-20 max-h-[250px] overflow-y-scroll">
                    {variants?.map((data, index) => {
                      return (
                        <p
                          className="py-[12px] px-[16px] text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
                          key={index}
                          onClick={() => {
                            setSelectedVariant(data.name);
                            setIsStoreVariantOpen(false);
                            if (data.name === "State") {
                              setSave(false);
                            }
                          }}
                        >
                          {data.name}
                        </p>
                      );
                    })}

                    <div className="px-4 cursor-pointer flex py-3 gap-1 border-t items-center mt-2">
                      <AddCircleOutline
                        style={{ color: "#344335", fontSize: "18px" }}
                      />
                      <p className="text-[#344335] font-semibold text-sm">
                        Add custom variant type
                      </p>
                    </div>
                  </div>
                )}

                <div className="relative z-10  rounded-lg bg-white h-fit border border-[#E1E6E1] mt-3">
                  <div className="flex flex-col">
                    <div className="border-b border-b-[#E1E6E1] px-4 py-3">
                      <p className="text-[#4B564D] font-semibold text-sm">
                        Variants
                      </p>
                    </div>

                    {save && (
                      <div className="px-4 py-3">
                        <div
                          className=" rounded-lg bg-[#F6F7F6] border border-[#E1E6E1]"
                          onClick={() => setSave(false)}
                        >
                          {/* STATE */}
                          <div className="relative w-full px-4 py-2 border-b border-b-[#E1E6E1]">
                            <p className="text-[#344335] font-semibold text-sm pb-1">
                              State
                            </p>
                          </div>

                          <div className="flex gap-3 items-center flex-wrap py-3 px-2">
                            {selectedState.map((state, index) => {
                              return (
                                <div
                                  key={index}
                                  className="bg-[#96A397] px-2 py-1 rounded-lg"
                                >
                                  <p className="text-[#FFFFFF] font-medium text-sm">
                                    {state.name}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}

                    {/* STATE VARIANT */}
                    {!save && (
                      <div className="">
                        {selectedVariant === "State" ? (
                          <div className="px-3 py-3">
                            <div className="px-2 py-3 rounded-lg bg-[#F6F7F6] border border-[#E1E6E1]">
                              {/* STATE */}
                              <div className="relative w-full">
                                <p className="text-[#344335] font-normal text-sm pb-1">
                                  Variant Type
                                </p>
                                <div
                                  className="flex justify-between items-center px-[16px] border border-[#E1E6E1] rounded-[8px] h-[44px] bg-white w-full cursor-pointer"
                                  onClick={handleStateClick}
                                >
                                  <p className="font-[500] text-sm leading-[20px] text-[#344335]">
                                    {selectedState.name || "Select State"}
                                  </p>
                                  <ExpandMore
                                    className="text-[#ABB6AC] cursor-pointer"
                                    style={{ fontSize: "18px" }}
                                  />
                                </div>
                                {isClickState && (
                                  <div className="absolute right-0 w-full rounded-lg bg-white padding-[8px] mt-[0.5%] cursor-pointer py-[8px] mb-[50px] z-20 max-h-[250px] overflow-y-scroll shadow-md">
                                    {stateData?.map((data, index) => {
                                      return (
                                        <div className="flex gap-3 items-center py-[12px] px-[16px]">
                                          <input
                                            type="checkbox"
                                            className="accent-primary w-4 h-4"
                                            checked={selectedState.some(
                                              (x) => x.id === data.id
                                            )}
                                            onChange={() => {
                                              handleSelectedState(
                                                data.id,
                                                data.name
                                              );
                                            }}
                                          />
                                          <p
                                            className=" text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
                                            key={index}
                                          >
                                            {data.display_name}
                                          </p>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>

                              {/* VARIANT OPTION */}
                              {selectedState.length !== 0 && (
                                <div className="relative z-10  rounded-lg bg-white h-fit border border-[#E1E6E1] mt-3">
                                  <div className="flex flex-col">
                                    <div className="border-b border-b-[#E1E6E1] px-4 py-3">
                                      <p className="text-[#4B564D] font-semibold text-sm">
                                        Variants Options
                                      </p>
                                    </div>

                                    <div className="px-4 py-3 flex flex-col gap-2">
                                      {selectedState?.map((state, index) => {
                                        return (
                                          <div
                                            key={index}
                                            onClick={() =>
                                              handleSelectedState(
                                                state.id,
                                                state.name
                                              )
                                            }
                                            className="border border-[#E1E6E1] cursor-pointer px-3 py-3 rounded-lg bg-white flex items-center justify-between"
                                          >
                                            <p className="text-[#344335] font-semibold text-sm">
                                              {state.name}
                                            </p>
                                            <img
                                              src={Delete}
                                              alt="delete icon"
                                            />
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              )}

                              {selectedState.length !== 0 && (
                                <div className="flex gap-3 items-center mt-3">
                                  <button
                                    disabled={false}
                                    onClick={() => {
                                      setSelectedState([]);
                                      setSelectedVariant("");
                                    }}
                                    className="outline-none cursor-pointer bg-white border disabled:bg-[#ABB6AC] disabled:border-[#ABB6AC] border-[#B92020] text-[#B92020] font-medium text-sm leading-5 py-[10px] px-[20px] rounded-[8px]  transition ease-in-out delay-100"
                                  >
                                    Delete
                                  </button>
                                  <button
                                    disabled={false}
                                    onClick={() => setSave(true)}
                                    className="outline-none cursor-pointer bg-primary border disabled:bg-[#ABB6AC] disabled:border-[#ABB6AC] border-primary text-white font-medium text-sm leading-5 py-[10px] px-[20px] rounded-[8px]  hover:border hover:border-primary  transition ease-in-out delay-100"
                                  >
                                    Save
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    )}

                    {saveSize && (
                      <div className="px-4 py-3">
                        <div
                          className=" rounded-lg bg-[#F6F7F6] border border-[#E1E6E1]"
                          onClick={() => setSaveSize(false)}
                        >
                          {/* STATE */}
                          <div className="relative w-full px-4 py-2 border-b border-b-[#E1E6E1]">
                            <p className="text-[#344335] font-semibold text-sm pb-1">
                              Size
                            </p>
                          </div>

                          <div className="flex gap-3 items-center flex-wrap py-3 px-2">
                            {selectedSize.map((size, index) => {
                              return (
                                <div
                                  key={index}
                                  className="bg-[#96A397] px-2 py-1 rounded-lg"
                                >
                                  <p className="text-[#FFFFFF] font-medium text-sm">
                                    {size.number}
                                    {size.unit}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}

                    {/* SIZE VARIANT */}
                    {!saveSize && (
                      <div className="">
                        {selectedVariant === "Size" ? (
                          <div className="px-3 py-3">
                            <div className="px-2 py-3 rounded-lg bg-[#F6F7F6] border border-[#E1E6E1]">
                              {/* STATE */}
                              <div className="relative w-full">
                                <p className="text-[#344335] font-normal text-sm pb-1">
                                  Variant Type
                                </p>
                                <div className="flex justify-between items-center px-[16px] border border-[#E1E6E1] rounded-[8px] h-[44px] bg-white w-full">
                                  <p className="font-[500] text-sm leading-[20px] text-[#344335]">
                                    Size
                                  </p>
                                </div>
                              </div>

                              {/* SIZE VARIANT OPTION */}

                              <div className="relative z-10  rounded-lg bg-white h-fit border border-[#E1E6E1] mt-3">
                                <div className="flex flex-col">
                                  <div className="border-b border-b-[#E1E6E1] px-4 py-3">
                                    <p className="text-[#4B564D] font-semibold text-sm">
                                      Variants Options
                                    </p>
                                  </div>

                                  <div className="px-4 py-3 flex flex-col gap-2">
                                    {selectedSize?.map((size, index) => {
                                      return (
                                        <div
                                          onKeyDown={handleEnterSize}
                                          key={index}
                                          className="flex gap-3 items-center"
                                        >
                                          <div className="w-[60%] border border-[#E1E6E1] bg-white px-2 py-2 rounded-lg">
                                            <input
                                              onChange={(event) =>
                                                setSelectedSize((prev) =>
                                                  prev.map((size, i) =>
                                                    i === index
                                                      ? {
                                                          ...size,
                                                          number:
                                                            event.target.value,
                                                        }
                                                      : size
                                                  )
                                                )
                                              }
                                              value={size.number}
                                              type="number"
                                              className="w-full border-none outline-none bg-transparent text-sm text-[#344335] font-semibold leading-5"
                                            />
                                          </div>
                                          <div className="w-[40%]">
                                            <div className="relative w-full">
                                              <div
                                                className="flex justify-between items-center px-[16px] border border-[#E1E6E1] rounded-[8px] h-[44px] bg-white w-full cursor-pointer"
                                                onClick={() =>
                                                  setToggleUnit(!toggleUnit)
                                                }
                                              >
                                                <p className="font-[500] text-sm leading-[20px] text-[#344335]">
                                                  {size.unit || "Select Unit"}
                                                </p>
                                                <ExpandMore
                                                  className="text-[#ABB6AC] cursor-pointer"
                                                  style={{ fontSize: "18px" }}
                                                />
                                              </div>
                                              {toggleUnit && (
                                                <div className="absolute right-0  w-full rounded-lg bg-white padding-[8px] mt-[0.5%] cursor-pointer py-[8px] mb-[50px] z-[200] max-h-[500px] overflow-y-scroll shadow-md">
                                                  {units?.map(
                                                    (data, unitIndex) => {
                                                      return (
                                                        <div
                                                          key={unitIndex}
                                                          className="flex gap-3 items-center py-[12px] px-[16px]"
                                                        >
                                                          <p
                                                            onClick={() => {
                                                              setSelectedSize(
                                                                (prev) =>
                                                                  prev.map(
                                                                    (size, i) =>
                                                                      i ===
                                                                      index
                                                                        ? {
                                                                            ...size,
                                                                            unit: data.name,
                                                                          }
                                                                        : size
                                                                  )
                                                              );
                                                              setToggleUnit(
                                                                false
                                                              );
                                                            }}
                                                            className=" text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
                                                            key={index}
                                                          >
                                                            {data.name}
                                                          </p>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>

                              {selectedSize.length !== 0 && (
                                <div className="flex gap-3 items-center mt-3">
                                  <button
                                    disabled={false}
                                    onClick={() => {
                                      setSelectedSize([]);
                                      setSelectedVariant("");
                                    }}
                                    className="outline-none cursor-pointer bg-white border disabled:bg-[#ABB6AC] disabled:border-[#ABB6AC] border-[#B92020] text-[#B92020] font-medium text-sm leading-5 py-[10px] px-[20px] rounded-[8px]  transition ease-in-out delay-100"
                                  >
                                    Delete
                                  </button>
                                  <button
                                    disabled={false}
                                    onClick={() => setSaveSize(true)}
                                    className="outline-none cursor-pointer bg-primary border disabled:bg-[#ABB6AC] disabled:border-[#ABB6AC] border-primary text-white font-medium text-sm leading-5 py-[10px] px-[20px] rounded-[8px]  hover:border hover:border-primary  transition ease-in-out delay-100"
                                  >
                                    Save
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    )}

                    <div
                      className="px-4 cursor-pointer flex py-3 gap-1 items-center"
                      onClick={() => {
                        setIsStoreVariantOpen(!isStoreVariantOpen);
                      }}
                    >
                      <AddCircleOutline
                        style={{ color: "#2B8C34", fontSize: "18px" }}
                      />
                      <p className="text-primary font-semibold text-sm">
                        Add Variant
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {formattedArray.length !== 0 && (save === true || saveSize) && (
                <div className="">
                  <div className="relative  rounded-lg bg-white h-fit border border-[#E1E6E1] mt-3">
                    <div className="px-4 py-4">
                      <p className="text-[#4B564D] font-semibold text-sm">
                        Variant quantity and pricing
                      </p>

                      {/* TABS */}
                      <div className="flex gap-3 items-center pt-3 cursor-pointer">
                        {stores?.map((store, index) => {
                          return (
                            <div
                              onClick={() => setSelectedTab(store.name)}
                              key={index}
                            >
                              <p
                                className={`font-semibold text-xs  ${
                                  selectedTab === store.name
                                    ? "text-primary underline underline-offset-[6px] decoration-primary decoration-[2px]"
                                    : "text-[#96A397]"
                                }`}
                              >
                                {store.name}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="h-[1.5px] w-full bg-[#E1E6E1] my-3" />

                    <div className="px-3 pb-3">
                      <div className="flex bg-[#F6F7F6] border border-[#E1E6E1] text-[#4B564D] px-4 py-3 rounded-lg">
                        <div className="w-1/2">
                          <p className="text-[#4B564D] font-semibold text-sm">
                            Variant
                          </p>
                        </div>
                        <div className="w-1/2 flex">
                          <div className="w-1/2 flex">
                            <p className="text-[#4B564D] font-semibold text-sm">
                              Price
                            </p>
                          </div>
                          <div className="w-1/2 flex items-end justify-end">
                            <p className="text-[#4B564D] font-semibold text-sm">
                              Quantity
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col w-full px-2">
                        {formattedArray?.map((data, index) => {
                          const lastState =
                            formattedArray[formattedArray.length - 1];
                          return (
                            <div
                              key={index}
                              className={`${
                                lastState.name === data.name
                                  ? ""
                                  : "border-b border-b-[#EDECE7]"
                              } `}
                            >
                              <div
                                key={index}
                                className={`flex items-center bg-white  text-[#4B564D] px-2 pt-3`}
                              >
                                <div className="w-1/2 flex items-center gap-2">
                                  <div className="bg-[#F6F7F6] border border-dashed border-[#E1E6E1] w-[44px] h-[44px] rounded-lg flex justify-center items-center">
                                    <AddPhotoAlternateOutlined
                                      style={{
                                        color: "#2B8C34",
                                        fontSize: "18px",
                                      }}
                                    />
                                  </div>
                                  <p className="text-[#4B564D] font-semibold text-sm">
                                    {data.name}{" "}
                                    <span>
                                      {selectedSize.length !== 1 &&
                                        selectedSize[0].number === "0" &&
                                        `/${data.number} ${data.unit}`}
                                    </span>
                                  </p>
                                </div>
                                <div className="w-1/2 flex items-center">
                                  <div className="w-1/2 flex">
                                    <div className="w-full rounded-lg bg-[white] border-[#E1E6E1] border text-[#344335] px-3 py-2 flex gap-1 items-center">
                                      <p className="text-[#4B564D] font-semibold text-sm">
                                        ₦
                                      </p>
                                      <input
                                        type="number"
                                        className="w-[120px] outline-none text-[#4B564D] font-semibold text-sm bg-none "
                                      />
                                    </div>
                                  </div>
                                  <div className="w-1/2 flex flex-col items-end justify-end">
                                    <input
                                      type="number"
                                      placeholder="0"
                                      className=" outline-none border-[#E1E6E1] border text-[#4B564D] px-3 py-2 rounded-lg w-[72px] flex items-end justify-end font-semibold text-sm bg-none "
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                onClick={() => setIsVariantModalOpen(true)}
                                className="flex justify-end items-end py-2"
                              >
                                <p className="text-primary font-semibold text-xs pr-[10px] cursor-pointer">
                                  Show more
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        {/* SECOND SECTION(PRODUCT DETAILS) */}
        <div className="w-[40%]">
          {/* STATUS */}
          <div className="relative z-10  rounded-lg bg-white h-fit border border-[#E1E6E1]">
            <div className="flex flex-col">
              <div className="border-b border-b-[#E1E6E1] px-4 py-3">
                <p className="text-[#4B564D] font-semibold text-sm">Status</p>
              </div>

              <div className="relative w-full px-4 py-3">
                <div
                  onClick={() => setIsStatusOpen(!isStatusOpen)}
                  className="flex justify-between items-center px-[16px] border border-[#96A397] rounded-[8px] h-[44px] w-full cursor-pointer"
                >
                  <p className="font-[500] text-sm leading-[20px] text-[#344335]">
                    {selectedStatus.name || "Select Status"}
                  </p>
                  <ExpandMore
                    className="text-[#ABB6AC] cursor-pointer"
                    style={{ fontSize: "18px" }}
                  />
                </div>

                {isStatusOpen && (
                  <div className="absolute right-0  w-full rounded-lg  bg-white padding-[8px] mt-[1%] border cursor-pointer py-[8px] mb-[50px] z-20 max-h-[250px] overflow-y-scroll shadow-md">
                    {status?.map((data, index) => {
                      return (
                        <p
                          className="py-[12px] px-[16px] text-sm font-medium leading-5 hover:bg-[#EDF7EE]"
                          key={index}
                          onClick={() => handleSelectedStatus(data)}
                        >
                          {data.name}
                        </p>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* PRODUCT DETAILS */}
          {type === "Bird Repellant" ||
          type === "Storage Product" ||
          type === "Rodenticide" ||
          type === "Nematicide" ? (
            <CropCategoryTwo
              categoryTwoData={categoryTwoData}
              setCategoryTwoData={setCategoryTwoData}
            />
          ) : null}

          {type === "Herbicide" ||
          type === "Insecticide" ||
          type === "Plant Growth Regulator Solution" ||
          type === "Fungicide" ? (
            <CropCategoryOne
              categoryOneData={categoryOneData}
              setCategoryOneData={setCategoryOneData}
            />
          ) : null}

          {type === "Fertilizers" ? (
            <CropCategoryThree
              categoryThreeData={categoryThreeData}
              setCategoryThreeData={setCategoryThreeData}
            />
          ) : null}
          {type === "Seed Dressing" ? (
            <CropCategoryFour
              categoryFourData={categoryFourData}
              setCategoryFourData={setCategoryFourData}
            />
          ) : null}
          {type === "Seed" ? (
            <CropCategoryFive
              categoryFiveData={categoryFiveData}
              setCategoryFiveData={setCategoryFiveData}
            />
          ) : null}
        </div>
      </div>

      {/* MODALSSSS */}

      {/* STORE MODAL */}
      <StoreModal
        isStoreModalOpen={isStoreModalOpen}
        setIsStoreModalOpen={setIsStoreModalOpen}
      />

      <VariantModal
        productName={productName}
        stores={stores}
        formattedArray={formattedArray}
        setFormattedArray={setFormattedArray}
        isVariantModalOpen={isVariantModalOpen}
        setIsVariantModalOpen={setIsVariantModalOpen}
        setStores={setStores}
        selectedSize={selectedSize}
        setIsStoreModalOpen={setIsStoreModalOpen}
      />
    </div>
  );
};

export default AddProduct;
