import React, { useEffect, useState } from "react";
import logo from "../../assets/icons/Logo.svg";
// import arrowDown from "../../assets/icons/keyboard_arrow_down.svg";
import notification_icon from "../../assets/icons/notifications.svg";
import chevron_down from "../../assets/icons/chevron_down.svg";
import chevron_up from "../../assets/icons/chevron_up.svg";
import money from "../../assets/icons/money.svg";
import farmer_icon from "../../assets/icons/farmer.svg";
import logout_icon from "../../assets/icons/logout.svg";
import money_active from "../../assets/icons/money_active.svg";
import farmer_active from "../../assets/icons/farmer_active.svg";
import approval_delegation_icon from "../../assets/icons/approval_delegation.svg";
import approval_delegation_activeicon from "../../assets/icons/approval_delegation_active.svg";
import DashboardIcon from "@mui/icons-material/Dashboard";
// import MoneyIcon from "@mui/icons-material/Money";
import Breadcrumb from "../../components/Breadcrumbs/breadcrumb";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PaymentsIcon from "@mui/icons-material/Payments";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import BarChartIcon from "@mui/icons-material/BarChart";
import { motion } from "framer-motion";
import {
  Call,
  Campaign,
  Close,
  Comment,
  ContentPaste,
  CorporateFare,
  // Discount,
  Email,
  EmojiEvents,
  ExpandLess,
  ExpandMore,
  MoneyOff,
  PeopleAlt,
  PeopleAltOutlined,
  // Group,
  PermPhoneMsg,
  PhoneAndroid,
  ShoppingBag,
  ShoppingCart,
} from "@mui/icons-material";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Logout } from "../../redux/Auth/auth";
// import useSound from "use-sound";
// import notify from "../../assets/sounds/dutifully-notification-tone.mp3";

const Sidebar = ({ children, breadcrumb }) => {
  let location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo, is_team_lead } = useSelector((state) => state.auth);
  const [isFarmerHover, setIsFarmerHover] = React.useState(false);
  const [isTransactionHover, setIsTransactionHover] = React.useState(false);
  const [isLoanHover, setIsLoanHover] = React.useState(false);
  const [farmerToggle, setFarmerToggle] = useState(true);
  const [callsToggle, setCallsToggle] = useState(true);
  const [marketplaceToggle, setMarketplaceToggle] = useState(true);
  const [ussdToggle, setUssdToggle] = useState(true);
  const [loanToggle, setLoanToggle] = useState(true);
  const [agentsToggle, setAgentsToggle] = useState(true);
  const [organizationToggle, setOrganizationToggle] = useState(true);
  const [isNavMenu, setNavMenu] = React.useState(false);
  const [notifications, setNotifications] = React.useState([]);
  const [newNotification, setNewNotification] = useState(false);
  // const [play] = useSound(notify);

  // const handleNotificationSound = useCallback(() => {
  //   play();
  // }, [play]);

  const toggleNavMenu = () => {
    setNavMenu(!isNavMenu);
  };

  const handleLogout = () => {
    dispatch(Logout());
    navigate("/");
  };

  useEffect(() => {
    const ws = new WebSocket("https://finag.herokuapp.com/ws/notifications/");

    ws.onopen = () => {
      console.log("Connected to WebSocket server.");
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log("Message from server:", message);
      // handleNotificationSound();
      setNotifications((prev) => [...prev, "New Message"]);
      setNewNotification(true);
      setTimeout(() => {
        setNewNotification(false);
      }, 5000);
    };

    ws.onclose = () => {
      console.log("Disconnected from WebSocket server.");
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => {
      ws.close();
    };
  }, [setNotifications]);

  console.log(notifications);

  return (
    <div className="hidden lg:flex h-screen bg-[#f5f5f5]">
      <div className="md:w-64 bg-primary flex-shrink-0 overflow-y-scroll pt -[30px] sidebar_scroll">
        <img
          src={logo}
          alt="logo"
          className="pl-[26.6px] pt-[40px]  overflow-y-scroll"
          // onClick={handleNotificationSound}
        />

        <div className=" cursor-pointer mt-[30px]">
          {/* OVERVIEW AND NOTIFICATIONS SECTION */}
          <section>
            <NavLink
              to="/overview"
              className={
                location.pathname === "/overview"
                  ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-primary bg-white w-[98%] rounded-r-[8px]"
                  : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-white hover:text-primary hover:bg-white w-[98%] rounded-r-[8px]"
              }
            >
              <DashboardIcon style={{ fontSize: "18px" }} />
              <p className="font-semibold text-sm capitalize leading-[18px]">
                Overview
              </p>
            </NavLink>

            <NavLink
              to="/notifications"
              className={
                location.pathname === "/notifications"
                  ? "flex justify-between items-center text-primary bg-white  mb-[13px] py-[13px] w-[98%] rounded-r-[8px]"
                  : "flex justify-between items-center hover:text-primary text-white hover:bg-white mb-[13px] py-[13px] w-[98%] rounded-r-[8px]"
              }
            >
              <div className="pl-[26.6px] flex gap-[18.5px] items-center font-semibold text-sm capitalize leading-[18px]">
                <NotificationsIcon style={{ fontSize: "18px" }} />
                <p className="">Notifications</p>
              </div>
            </NavLink>
          </section>

          {/* FARMER SECTION */}
          <section>
            {/* HEADER */}
            <div
              className="flex justify-between items-center pl-[26.6px] cursor-pointer pb-[18px]"
              onClick={() => setFarmerToggle(!farmerToggle)}
            >
              <p className="font-bold text-xs leading-[18px] text-[#EDF7EE] ">
                FARMER
              </p>
              {farmerToggle ? (
                <ExpandLess
                  style={{
                    fontSize: "17px",
                    marginRight: "20px",
                    color: "#FFF",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    fontSize: "17px",
                    marginRight: "20px",
                    color: "#FFF",
                  }}
                />
              )}
            </div>

            {/*  */}
            {farmerToggle && (
              <div>
                <NavLink to="/farmers">
                  <div
                    onMouseEnter={() => setIsFarmerHover(true)}
                    onMouseLeave={() => setIsFarmerHover(false)}
                    className={
                      location.pathname.includes("farmers") ||
                      location.pathname.includes("profiles") ||
                      location.pathname.includes("edit-profile") ||
                      isFarmerHover
                        ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]   text-primary bg-white w-[98%] rounded-r-[8px]"
                        : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-white hover:text-primary hover:bg-white w-[98%] rounded-r-[8px]"
                    }
                  >
                    {location.pathname.includes("farmers") ||
                    location.pathname.includes("profiles") ||
                    location.pathname.includes("edit-profile") ||
                    isFarmerHover ? (
                      <img src={farmer_active} alt="farmer" className="" />
                    ) : (
                      <img src={farmer_icon} alt="farmer" className="" />
                    )}

                    <p className="font-semibold text-sm capitalize leading-[18px]">
                      Farmer
                    </p>
                  </div>
                </NavLink>

                <NavLink
                  to="/messages"
                  className={
                    location.pathname.includes("/messages")
                      ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-primary bg-white py-[13px] w-[98%] rounded-r-[8px]"
                      : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-white hover:text-primary hover:bg-white py-[13px] w-[98%] rounded-r-[8px]"
                  }
                >
                  <Email style={{ fontSize: "18px" }} />
                  <p className="">Messages</p>
                </NavLink>

                {/* POINTS */}
                {process.env.REACT_APP_ENVIRONMENT === "staging" ? (
                  <NavLink
                    to="/points"
                    className={
                      location.pathname.includes("/points")
                        ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-primary bg-white py-[13px] w-[98%] rounded-r-[8px]"
                        : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-white hover:text-primary hover:bg-white py-[13px] w-[98%] rounded-r-[8px]"
                    }
                  >
                    <EmojiEvents style={{ fontSize: "18px" }} />
                    <p className="">Points</p>
                  </NavLink>
                ) : null}

                <NavLink
                  to="/complaints"
                  className={
                    location.pathname.includes("complaints")
                      ? "flex justify-between items-center text-primary bg-white  mb-[13px] py-[13px] w-[98%] rounded-r-[8px]"
                      : "flex justify-between items-center hover:text-primary text-white hover:bg-white mb-[13px] py-[13px] w-[98%] rounded-r-[8px]"
                  }
                >
                  <div className="pl-[26.6px] flex gap-[18.5px] items-center   font-semibold text-sm capitalize leading-[18px] ">
                    <Comment style={{ fontSize: "18px" }} />
                    <p className="">Complaints</p>
                  </div>

                  {/* BADGE */}
                  {/* <div className="bg-[#ECEEEC] w-[19px] h-[20px] rounded-[4px] flex items-center justify-center text-[#344335] text-xs font-medium mr-[32px]">
                  <p>{complaintsTotal}</p>
                </div> */}
                </NavLink>

                <NavLink
                  to="/cooperative"
                  className={
                    location.pathname.includes("cooperative")
                      ? "flex justify-between items-center text-primary bg-white  mb-[13px] py-[13px] w-[98%] rounded-r-[8px]"
                      : "flex justify-between items-center hover:text-primary text-white hover:bg-white mb-[13px] py-[13px] w-[98%] rounded-r-[8px]"
                  }
                >
                  <div className="pl-[26.6px] flex gap-[18.5px] items-center   font-semibold text-sm capitalize leading-[18px] ">
                    <CorporateFare style={{ fontSize: "18px" }} />
                    <p className="">Cooperative</p>
                  </div>
                </NavLink>

                <>
                  {userInfo?.role === "SALES_AGENT_LEAD" && (
                    <NavLink
                      to="/debit-farmer"
                      className={
                        location.pathname.includes("/debit-farmer")
                          ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-primary bg-white py-[13px] w-[98%] rounded-r-[8px]"
                          : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-white hover:text-primary hover:bg-white py-[13px] w-[98%] rounded-r-[8px]"
                      }
                    >
                      <MoneyOff style={{ fontSize: "18px" }} />
                      <p className="">Debit farmer</p>
                    </NavLink>
                  )}
                </>
              </div>
            )}
          </section>

          {/* CALLS SECTION */}
          <section>
            {/* HEADER */}
            {/* HEADER */}
            <div
              className="flex justify-between items-center pl-[26.6px] cursor-pointer pb-[18px]"
              onClick={() => setCallsToggle(!callsToggle)}
            >
              <p className="font-bold text-xs leading-[18px] text-[#EDF7EE] ">
                CALLS
              </p>
              {callsToggle ? (
                <ExpandLess
                  style={{
                    fontSize: "17px",
                    marginRight: "20px",
                    color: "#FFF",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    fontSize: "17px",
                    marginRight: "20px",
                    color: "#FFF",
                  }}
                />
              )}
            </div>

            {/*  */}
            {callsToggle && (
              <div>
                <NavLink
                  to="/call-request"
                  className={
                    location.pathname.includes("/call-request")
                      ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-primary bg-white py-[13px] w-[98%] rounded-r-[8px]"
                      : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-white hover:text-primary hover:bg-white py-[13px] w-[98%] rounded-r-[8px]"
                  }
                >
                  <Call style={{ fontSize: "18px" }} />
                  <p className="font-semibold text-sm capitalize leading-[18px]">
                    Request
                  </p>
                </NavLink>

                {/* <NavLink
                to="/call-log"
                className={
                  location.pathname.includes("/call-log")
                    ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-primary bg-white py-[13px] w-[98%] rounded-r-[8px]"
                    : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-white hover:text-primary hover:bg-white py-[13px] w-[98%] rounded-r-[8px]"
                }
              >
                <ListAlt style={{ fontSize: "18px" }} />
                <p className="">Log</p>
              </NavLink> */}
              </div>
            )}
          </section>

          {/* MARKETPLACE SECTION */}
          <section>
            {/* HEADER */}

            <div
              className="flex justify-between items-center pl-[26.6px] cursor-pointer pb-[18px]"
              onClick={() => setMarketplaceToggle(!marketplaceToggle)}
            >
              <p className="font-bold text-xs leading-[18px] text-[#EDF7EE] ">
                MARKETPLACE
              </p>
              {marketplaceToggle ? (
                <ExpandLess
                  style={{
                    fontSize: "17px",
                    marginRight: "20px",
                    color: "#FFF",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    fontSize: "17px",
                    marginRight: "20px",
                    color: "#FFF",
                  }}
                />
              )}
            </div>

            {/*  */}
            {marketplaceToggle && (
              <div>
                <NavLink
                  to="/call-to-order"
                  className={
                    location.pathname.includes("/call-to-order")
                      ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-primary bg-white py-[13px] w-[98%] rounded-r-[8px]"
                      : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-white hover:text-primary hover:bg-white py-[13px] w-[98%] rounded-r-[8px]"
                  }
                >
                  <PermPhoneMsg style={{ fontSize: "18px" }} />
                  <p className="font-semibold text-sm capitalize leading-[18px]">
                    Call To Order
                  </p>
                </NavLink>

                <NavLink
                  to="/product-catalogue"
                  className={
                    location.pathname.includes("/product-catalogue") ||
                    location.pathname.includes("/add-product")
                      ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-primary bg-white py-[13px] w-[98%] rounded-r-[8px]"
                      : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-white hover:text-primary hover:bg-white py-[13px] w-[98%] rounded-r-[8px]"
                  }
                >
                  <ContentPaste style={{ fontSize: "18px" }} />
                  <p className="">Product Catalogue</p>
                </NavLink>

                <NavLink
                  to="/orders"
                  className={
                    location.pathname.includes("/order")
                      ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-primary bg-white py-[13px] w-[98%] rounded-r-[8px]"
                      : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-white hover:text-primary hover:bg-white py-[13px] w-[98%] rounded-r-[8px]"
                  }
                >
                  <ShoppingCart style={{ fontSize: "18px" }} />
                  <p className="">Orders</p>
                </NavLink>

                <NavLink
                  to="/retail"
                  className={
                    location.pathname.includes("/retail") ||
                    location.pathname.includes("/calls") ||
                    location.pathname.includes("/follow-up") ||
                    location.pathname.includes("/sales")
                      ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-primary bg-white py-[13px] w-[98%] rounded-r-[8px]"
                      : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-white hover:text-primary hover:bg-white py-[13px] w-[98%] rounded-r-[8px]"
                  }
                >
                  <ShoppingBag style={{ fontSize: "18px" }} />
                  <p className="">Retail</p>
                </NavLink>

                <NavLink
                  to="/campaign"
                  className={
                    location.pathname.includes("campaign")
                      ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-primary bg-white py-[13px] w-[98%] rounded-r-[8px]"
                      : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-white hover:text-primary hover:bg-white py-[13px] w-[98%] rounded-r-[8px]"
                  }
                >
                  <Campaign style={{ fontSize: "18px" }} />
                  <p className="">Campaign</p>
                </NavLink>

                {/* <div className="pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-white hover:text-primary hover:bg-white py-[13px] w-[98%] rounded-r-[8px]">
                <Discount style={{ fontSize: "18px" }} />
                <p className="">Discount</p>
              </div> */}
              </div>
            )}
          </section>

          {/* USSD SECTION */}
          <section>
            <div
              className="flex justify-between items-center pl-[26.6px] cursor-pointer pb-[18px]"
              onClick={() => setUssdToggle(!ussdToggle)}
            >
              <p className="font-bold text-xs leading-[18px] text-[#EDF7EE] ">
                USSD
              </p>
              {ussdToggle ? (
                <ExpandLess
                  style={{
                    fontSize: "17px",
                    marginRight: "20px",
                    color: "#FFF",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    fontSize: "17px",
                    marginRight: "20px",
                    color: "#FFF",
                  }}
                />
              )}
            </div>

            {/*  */}
            {ussdToggle && (
              <div>
                <NavLink
                  to="/ussd-sessions"
                  className={
                    location.pathname.includes("/ussd-sessions")
                      ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-primary bg-white py-[13px] w-[98%] rounded-r-[8px]"
                      : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-white hover:text-primary hover:bg-white py-[13px] w-[98%] rounded-r-[8px]"
                  }
                >
                  <PhoneAndroid style={{ fontSize: "18px" }} />
                  <p className="font-semibold text-sm capitalize leading-[18px]">
                    Sessions
                  </p>
                </NavLink>

                <NavLink to="/transactions">
                  <div
                    className={
                      location.pathname.includes("/transaction") ||
                      isTransactionHover
                        ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-primary bg-white py-[13px] w-[98%] rounded-r-[8px]"
                        : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-white hover:text-primary hover:bg-white py-[13px] w-[98%] rounded-r-[8px]"
                    }
                    onMouseEnter={() => setIsTransactionHover(true)}
                    onMouseLeave={() => setIsTransactionHover(false)}
                  >
                    {location.pathname.includes("/transaction") ||
                    isTransactionHover ? (
                      <img src={money_active} alt="farmer" className="" />
                    ) : (
                      <img src={money} alt="farmer" className="" />
                    )}

                    <p className="">Transactions</p>
                  </div>
                </NavLink>

                {/* <NavLink
                to="/IVR"
                className={
                  location.pathname.includes("/IVR")
                    ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-primary bg-white py-[13px] w-[98%] rounded-r-[8px]"
                    : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-white hover:text-primary hover:bg-white py-[13px] w-[98%] rounded-r-[8px]"
                }
              >
                <PhoneAndroid style={{ fontSize: "18px" }} />
                <p className="font-semibold text-sm capitalize leading-[18px]">
                  IVR Sessions
                </p>
              </NavLink> */}
              </div>
            )}
          </section>

          {/* C2C AGENTS */}
          <section>
            <div
              className="flex justify-between items-center pl-[26.6px] cursor-pointer pb-[18px]"
              onClick={() => setAgentsToggle(!agentsToggle)}
            >
              <p className="font-bold text-xs leading-[18px] text-[#EDF7EE] ">
                C2C AGENTS
              </p>
              {agentsToggle ? (
                <ExpandLess
                  style={{
                    fontSize: "17px",
                    marginRight: "20px",
                    color: "#FFF",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    fontSize: "17px",
                    marginRight: "20px",
                    color: "#FFF",
                  }}
                />
              )}
            </div>

            {/*  */}
            {agentsToggle && (
              <div>
                <NavLink
                  to="/agents"
                  className={
                    location.pathname.includes("/agent")
                      ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-primary bg-white py-[13px] w-[98%] rounded-r-[8px]"
                      : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-white hover:text-primary hover:bg-white py-[13px] w-[98%] rounded-r-[8px]"
                  }
                >
                  <PeopleAlt style={{ fontSize: "18px" }} />
                  <p className="font-semibold text-sm capitalize leading-[18px]">
                    Agents
                  </p>
                </NavLink>
              </div>
            )}
          </section>

          {/* AGENTS SECTION */}
          <section>
            <div
              className="flex justify-between items-center pl-[26.6px] cursor-pointer pb-[18px]"
              onClick={() => setLoanToggle(!loanToggle)}
            >
              <p className="font-bold text-xs leading-[18px] text-[#EDF7EE] ">
                LOAN MANAGEMENT
              </p>
              {loanToggle ? (
                <ExpandLess
                  style={{
                    fontSize: "17px",
                    marginRight: "20px",
                    color: "#FFF",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    fontSize: "17px",
                    marginRight: "20px",
                    color: "#FFF",
                  }}
                />
              )}
            </div>

            {/*  */}
            {/* <div>
              <div className="pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-white hover:text-primary hover:bg-white w-[98%] rounded-r-[8px]">
                <Group style={{ fontSize: "18px" }} />
                <p className="font-semibold text-sm capitalize leading-[18px]">
                  Agents
                </p>
              </div>
            </div> */}
            {loanToggle && (
              <>
                {" "}
                {is_team_lead && (
                  <NavLink
                    to="/loan-overview"
                    className={
                      location.pathname.includes("/loan-overview")
                        ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-primary bg-white py-[13px] w-[98%] rounded-r-[8px]"
                        : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-white hover:text-primary hover:bg-white py-[13px] w-[98%] rounded-r-[8px]"
                    }
                  >
                    <BarChartIcon style={{ fontSize: "18px" }} />
                    <p className="font-semibold text-sm capitalize leading-[18px]">
                      Overview
                    </p>
                  </NavLink>
                )}
                {is_team_lead && (
                  <NavLink
                    to="/agrofastcash"
                    className={
                      location.pathname.includes("/agrofastcash") || isLoanHover
                        ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-primary bg-white py-[13px] w-[98%] rounded-r-[8px]"
                        : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-white hover:text-primary hover:bg-white py-[13px] w-[98%] rounded-r-[8px]"
                    }
                    onMouseEnter={() => setIsLoanHover(true)}
                    onMouseLeave={() => setIsLoanHover(false)}
                  >
                    {location.pathname.includes("/agrofastcash") ||
                    isLoanHover ? (
                      <img
                        src={approval_delegation_activeicon}
                        alt="approval delegation icon"
                        className=""
                      />
                    ) : (
                      <img
                        src={approval_delegation_icon}
                        alt="approval delegation icon"
                        className=""
                      />
                    )}
                    <p className="font-semibold text-sm capitalize leading-[18px]">
                      Loans
                    </p>
                  </NavLink>
                )}
                {is_team_lead && (
                  <NavLink
                    to="/loan-recovery"
                    className={
                      location.pathname.includes("/loan-recovery")
                        ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-primary bg-white py-[13px] w-[98%] rounded-r-[8px]"
                        : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-white hover:text-primary hover:bg-white py-[13px] w-[98%] rounded-r-[8px]"
                    }
                  >
                    <PaymentsIcon style={{ fontSize: "18px" }} />
                    <p className="font-semibold text-sm capitalize leading-[18px]">
                      Recovery
                    </p>
                  </NavLink>
                )}
                {is_team_lead && (
                  <NavLink
                    to="/equity"
                    className={
                      location.pathname.includes("/equity")
                        ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-primary bg-white py-[13px] w-[98%] rounded-r-[8px]"
                        : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-white hover:text-primary hover:bg-white py-[13px] w-[98%] rounded-r-[8px]"
                    }
                  >
                    <AccountBalanceIcon style={{ fontSize: "18px" }} />
                    <p className="font-semibold text-sm capitalize leading-[18px]">
                      Equity
                    </p>
                  </NavLink>
                )}
              </>
            )}
          </section>

          {/* ORGANIZATION SECTION */}
          <section>
            {/* HEADER */}

            <div
              className="flex justify-between items-center pl-[26.6px] cursor-pointer pb-[18px]"
              onClick={() => setOrganizationToggle(!organizationToggle)}
            >
              <p className="font-bold text-xs leading-[18px] text-[#EDF7EE] ">
                ORGANIZATION
              </p>
              {organizationToggle ? (
                <ExpandLess
                  style={{
                    fontSize: "17px",
                    marginRight: "20px",
                    color: "#FFF",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    fontSize: "17px",
                    marginRight: "20px",
                    color: "#FFF",
                  }}
                />
              )}
            </div>

            {organizationToggle && (
              <>
                {" "}
                <NavLink
                  to="/teammate-management"
                  className={
                    location.pathname.includes("/teammate-management")
                      ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-primary bg-white py-[13px] w-[98%] rounded-r-[8px]"
                      : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] font-semibold text-sm capitalize leading-[18px] text-white hover:text-primary hover:bg-white py-[13px] w-[98%] rounded-r-[8px]"
                  }
                >
                  <PeopleAltOutlined style={{ fontSize: "18px" }} />
                  <p className="font-semibold text-sm capitalize leading-[18px]">
                    Teammate Management
                  </p>
                </NavLink>
              </>
            )}
          </section>
        </div>
      </div>

      <div className="py-4 flex-grow flex-auto flex-shrink overflow-y-scroll">
        {/* NAV BAR */}
        <div className="flex px-4 md:px-6 justify-between items-center">
          <div>
            <Breadcrumb breadcrumb={breadcrumb} />
          </div>
          <div className="flex gap-4 items-center">
            <div className="bg-[#2B8C34] flex justify-center items-center w-[32px] h-[32px] rounded-full relative">
              <img src={notification_icon} alt="notification" />
              <div className="bg-[#B92043] w-[10px] h-[10px] rounded-full absolute -right-1 top-1 border-white border-2"></div>
            </div>
            <div className="bg-[#2B8C34] flex justify-center items-center w-[32px] h-[32px] rounded-full">
              <p className="text-white text-xs font-medium">
                {userInfo ? userInfo?.first_name?.charAt(0).toUpperCase() : "T"}
                {userInfo ? userInfo?.last_name?.charAt(0).toUpperCase() : "U"}
              </p>
            </div>
            <div
              className="flex items-center gap-2 cursor-pointer relative"
              onClick={toggleNavMenu}
            >
              <p className="text-[#5C715E] text-sm font-medium">
                {userInfo ? userInfo?.first_name : "Test"}{" "}
                {userInfo ? userInfo?.last_name : "User"}
              </p>
              <img
                src={isNavMenu ? chevron_up : chevron_down}
                alt="chevron arrow"
              />

              <ul
                className={
                  isNavMenu
                    ? "absolute top-10 transition-all right-0 bg-[#fff] rounded-[10px] min-w-[162px] border shadow-drop z-10"
                    : "absolute hidden transition-all top-20 right-0 bg-[#fff] rounded-[10px] min-w-[162px] border shadow-drop"
                }
              >
                <Link
                  to="/profile"
                  className="px-6 py-3 hover:bg-[#EDF7EE] flex hover:rounded-tr-[10px] hover:rounded-tl-[10px] transition-all"
                >
                  <p className="text-[#344335] text-sm font-medium">
                    View Profile
                  </p>
                </Link>
                <li
                  onClick={handleLogout}
                  className="px-6 py-3 hover:bg-[#EDF7EE] hover:rounded-bl-[10px] hover:rounded-br-[10px] transition-all flex item-center gap-2"
                >
                  <img src={logout_icon} alt="logout" />
                  <p className="text-[#344335] text-sm font-medium">Log Out</p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* CHILDREN */}
        <div className="px-4 md:px-6 py-6 relative h-screen overflow-auto">
          {children}

          {newNotification && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              className="fixed right-5 bottom-5 w-[350px] bg-white shadow-lg rounded-lg p-4 flex items-start space-x-3 border border-gray-200"
            >
              <NotificationsIcon className="text-primary" />
              <div className="flex-1">
                <h4 className="font-semibold text-gray-800">
                  New Notification
                </h4>
                <p className="text-gray-600 text-sm">
                  Your order has been shipped successfully!
                </p>
              </div>
              <button
                onClick={() => setNewNotification(false)}
                className="text-gray-400 hover:text-gray-600"
              >
                <Close style={{ fontSize: "18px" }} />
              </button>
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
