import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl, finagApiUrl } from "../config";
import cogoToast from "cogo-toast";
import { Mixpanel } from "../../components/Helpers/MixPanel";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

//////////////////////////////////////////// ALL ORDERS SECTION
export const getOrders = createAsyncThunk(
  "getOrders",
  async ({ page, orderType }, { rejectWithValue, dispatch }) => {
    if (orderType === "All") {
      try {
        const res = await axios.get(`${apiUrl}orders?page=${page}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Input") {
      try {
        const res = await axios.get(`${apiUrl}input_orders?page=${page}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Livestock") {
      try {
        const res = await axios.get(`${apiUrl}livestock_orders?page=${page}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Insurance") {
      try {
        const res = await axios.get(`${apiUrl}insurance_orders?page=${page}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Advisory") {
      try {
        const res = await axios.get(`${apiUrl}advisory_orders?page=${page}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Mechanization") {
      try {
        const res = await axios.get(
          `${apiUrl}mechanization_orders?page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    }
  }
);

/////////////////////////////////////////// SEARCH ORDERS
export const searchOrders = createAsyncThunk(
  "searchOrders",
  async ({ page, orderType, value }, { rejectWithValue, dispatch }) => {
    if (orderType === "All") {
      try {
        const res = await axios.get(
          `${apiUrl}orders?search=${value}&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Input") {
      try {
        const res = await axios.get(
          `${apiUrl}input_orders?search=${value}&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Livestock") {
      try {
        const res = await axios.get(
          `${apiUrl}livestock_orders?search=${value}&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Insurance") {
      try {
        const res = await axios.get(
          `${apiUrl}insurance_orders?search=${value}&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Advisory") {
      try {
        const res = await axios.get(
          `${apiUrl}advisory_orders?search=${value}&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Mechanization") {
      try {
        const res = await axios.get(
          `${apiUrl}mechanization_orders?search=${value}&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    }
  }
);

//////////////////////////////////////////// GET ORDER SECTION
export const getAnOrder = createAsyncThunk(
  "getAnOrder",
  async ({ orderType, id }, { rejectWithValue, dispatch }) => {
    if (orderType === "All") {
      try {
        const res = await axios.get(
          `${finagApiUrl}sharp-input-orders/${id}/retrieve/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Input") {
      try {
        const res = await axios.get(`${apiUrl}input_orders/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Livestock") {
      try {
        const res = await axios.get(
          `${finagApiUrl}sharp-input-orders/${id}/retrieve/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Insurance") {
      try {
        const res = await axios.get(`${apiUrl}insurance_orders/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Advisory") {
      try {
        const res = await axios.get(`${apiUrl}advisory_orders/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Mechanization") {
      try {
        const res = await axios.get(`${apiUrl}mechanization_orders/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    }
  }
);

/////////////////////////////////////////// Update Delivery Address(NEW)
export const updateDeliveryAddress = createAsyncThunk(
  "updateDeliveryAddress",
  async ({ value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${finagApiUrl}sharp-input-orders/update-delivery-address/`,
        value,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.data.errors.error.length > 0) {
        cogoToast.error(res.data.errors.error[0]);
      } else {
        cogoToast.success("Delivery  details updated successfully!");
      }
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

//////////////////////////////////////////// Accept Order (sales)(NEW)
export const acceptOrders = createAsyncThunk(
  "acceptOrders",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${finagApiUrl}sharp-input-orders/${id}/accept-order/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

/////////////////////////////////////////// Reject Order(NEW)
export const rejectOrder = createAsyncThunk(
  "rejectOrder",
  async ({ value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${finagApiUrl}sharp-input-orders/reject-order/`,
        value,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.data.errors.error.length > 0) {
        cogoToast.error(res.data.errors.error[0]);
      } else {
        cogoToast.success("Order rejected successfully!");
      }
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

////////////////////////////////////////////  UPDATE ORDER SECTION
export const updateAnOrder = createAsyncThunk(
  "updateAnOrder",
  async (
    {
      orderStatus,
      type,
      id,
      userId,
      farmerName,
      farmerPhonenumber,
      productName,
      price,
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    const userid = getState().auth.userInfo.user_id;
    const username = getState().auth.userInfo.username;

    if (type === "All") {
      try {
        const res = await axios.put(
          `${apiUrl}orders/${id}`,
          { order_status: orderStatus, updated_by_id: userId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          Mixpanel.identify(userid);
          Mixpanel.track("Order Status Update", {
            "Order Status": orderStatus.at(-1),
            "Updated By": username,
            "Farmer Name": farmerName,
            "Farmer Phonenumber": farmerPhonenumber,
            "Product Name": productName,
            Price: price,
          });

          cogoToast.success("Order status updated successfully");
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (type === "Input") {
      try {
        const res = await axios.put(
          `${apiUrl}input_orders/${id}`,
          { order_status: orderStatus, updated_by_id: userId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          Mixpanel.identify(userid);
          Mixpanel.track("Order Status Update", {
            "Order Status": orderStatus.at(-1),
            "Updated By": username,
            "Farmer Name": farmerName,
            "Farmer Phonenumber": farmerPhonenumber,
            "Product Name": productName,
            Price: price,
          });

          cogoToast.success("Order status updated successfully");
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (type === "Livestock") {
      try {
        const res = await axios.put(
          `${apiUrl}livestock_orders/${id}`,
          { order_status: orderStatus, updated_by_id: userId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          Mixpanel.identify(userid);
          Mixpanel.track("Order Status Update", {
            "Order Status": orderStatus.at(-1),
            "Updated By": username,
            "Farmer Name": farmerName,
            "Farmer Phonenumber": farmerPhonenumber,
            "Product Name": productName,
            Price: price,
          });

          cogoToast.success("Order status updated successfully");
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (type === "Insurance") {
      try {
        const res = await axios.put(
          `${apiUrl}insurance_orders/${id}`,
          { order_status: orderStatus, updated_by_id: userId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          Mixpanel.identify(userid);
          Mixpanel.track("Order Status Update", {
            "Order Status": orderStatus.at(-1),
            "Updated By": username,
            "Farmer Name": farmerName,
            "Farmer Phonenumber": farmerPhonenumber,
            "Product Name": productName,
            Price: price,
          });
          cogoToast.success("Order status updated successfully");
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (type === "Advisory") {
      try {
        const res = await axios.put(
          `${apiUrl}advisory_orders/${id}`,
          { order_status: orderStatus, updated_by_id: userId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          Mixpanel.identify(userid);
          Mixpanel.track("Order Status Update", {
            "Order Status": orderStatus.at(-1),
            "Updated By": username,
            "Farmer Name": farmerName,
            "Farmer Phonenumber": farmerPhonenumber,
            "Product Name": productName,
            Price: price,
          });

          cogoToast.success("Order status updated successfully");
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (type === "Mechanization") {
      try {
        const res = await axios.put(
          `${apiUrl}mechanization_orders/${id}`,
          { order_status: orderStatus, updated_by_id: userId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          Mixpanel.identify(userid);
          Mixpanel.track("Order Status Update", {
            "Order Status": orderStatus.at(-1),
            "Updated By": username,
            "Farmer Name": farmerName,
            "Farmer Phonenumber": farmerPhonenumber,
            "Product Name": productName,
            Price: price,
          });

          cogoToast.success("Order status updated successfully");
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    }
  }
);

// GET ALL ORDERS STATS
export const getOrdersStats = createAsyncThunk(
  "getOrdersStats",
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${finagApiUrl}cto-orders-vendors-count`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

// GET ALL OVERDUE ORDERS
export const getOverdueOrders = createAsyncThunk(
  "getOverdueOrders",
  async ({ page, orderType }, { rejectWithValue, dispatch }) => {
    if (orderType === "All") {
      try {
        const res = await axios.get(
          `${finagApiUrl}overdue-orders?page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Input") {
      try {
        const res = await axios.get(
          `${finagApiUrl}overdue-orders/crop-input?page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Livestock") {
      try {
        const res = await axios.get(
          `${finagApiUrl}overdue-orders/livestock_orders?page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Insurance") {
      try {
        const res = await axios.get(
          `${finagApiUrl}overdue-orders/insurance?page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Advisory") {
      try {
        const res = await axios.get(
          `${finagApiUrl}overdue-orders/advisory?page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Mechanization") {
      try {
        const res = await axios.get(
          `${finagApiUrl}overdue-orders/mechanization?page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    }
  }
);

// GET Vendor Payment
export const getVendorPayment = createAsyncThunk(
  "getVendorPayment",
  async ({ page, value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${finagApiUrl}all-orders?page=${page}&paid_vendor=${value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

// GET Vendor Payment INFOs
export const getVendorPaymentInfo = createAsyncThunk(
  "getVendorPaymentInfo",
  async ({ id, value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${finagApiUrl}all-orders/${value}/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const vendorPaymentApproval = createAsyncThunk(
  "vendorPaymentApproval",
  async ({ value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${finagApiUrl}organization-transfer/transfer/`,
        value,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.data.errors.error.length > 0) {
        cogoToast.error(res.data.errors.error[0]);
      } else {
        cogoToast.success("Vendor paid successfully!");
      }
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getRefunds = createAsyncThunk(
  "getRefunds",
  async ({ page, orderType }, { rejectWithValue, dispatch }) => {
    if (orderType === "All") {
      try {
        const res = await axios.get(`${finagApiUrl}refunds/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        console.log(error);
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Approved") {
      try {
        const res = await axios.get(`${finagApiUrl}refunds?qs=approved`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Pending") {
      try {
        const res = await axios.get(`${finagApiUrl}refunds?qs=pending`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Rejected") {
      try {
        const res = await axios.get(`${finagApiUrl}refunds?qs=denied`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    }
  }
);

export const refundsInfo = createAsyncThunk(
  "refundsInfo",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${finagApiUrl}refund/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const refundsApproval = createAsyncThunk(
  "refundsApproval",
  async ({ id, value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.patch(
        `${finagApiUrl}refund/${id}/`,
        { approval_status: value },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (
        res.status === 200 ||
        res.status === 201 ||
        res.status === 202 ||
        res.status === 203
      ) {
        cogoToast.success("Refund approved successfully!");
        return res.data;
      }
    } catch (error) {
      if (error.response && error.response) {
        if (error?.response?.data?.detail) {
          cogoToast.error(error?.response?.data?.detail);
        } else {
          cogoToast.error(error?.response?.data?.error);
        }

        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getVendorsStats = createAsyncThunk(
  "getVendorsStats",
  async ({ page, orderType }, { rejectWithValue, dispatch }) => {
    if (orderType === "All") {
      try {
        const res = await axios.get(
          `${finagApiUrl}all-suppliers/delivery-status?page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Advisory") {
      try {
        const res = await axios.get(
          `${finagApiUrl}advisory-supplier/delivery-status?page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Insurance") {
      try {
        const res = await axios.get(
          `${finagApiUrl}insurance-supplier/delivery-status?page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Crop Input") {
      try {
        const res = await axios.get(
          `${finagApiUrl}input-supplier/delivery-status?page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Mechanization") {
      try {
        const res = await axios.get(
          `${finagApiUrl}mechanization-supplier/delivery-status?page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Livestock Input") {
      try {
        const res = await axios.get(
          `${finagApiUrl}livestock-supplier/delivery-status?page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    }
  }
);

export const vendorInfo = createAsyncThunk(
  "vendorInfo",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${finagApiUrl}refund/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        cogoToast.error("An error occurred!");
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const vendorDetails = createAsyncThunk(
  "vendorDetails",
  async ({ id, orderType }, { rejectWithValue, dispatch }) => {
    if (orderType === "Advsiory") {
      try {
        const res = await axios.get(
          `${finagApiUrl}vendor-profile/advisory/${id}/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          // cogoToast.error("Vendor data not found");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Insurance") {
      try {
        const res = await axios.get(
          `${finagApiUrl}vendor-profile/insurance/${id}/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          // cogoToast.error("Vendor data not found");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Mechanization") {
      try {
        const res = await axios.get(
          `${finagApiUrl}vendor-profile/mechanization/${id}/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          // cogoToast.error("Vendor data not found");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Crop Input") {
      try {
        const res = await axios.get(
          `${finagApiUrl}vendor-profile/crop-input/${id}/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          // cogoToast.error("Vendor data not found");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Livestock Input") {
      try {
        const res = await axios.get(
          `${finagApiUrl}vendor-profile/livestock-input/${id}/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          // cogoToast.error("Vendor data not found");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    }
  }
);

export const vendorOrders = createAsyncThunk(
  "vendorOrders",
  async ({ id, orderType, page, status }, { rejectWithValue, dispatch }) => {
    if (orderType === "Advsiory") {
      try {
        const res = await axios.get(
          `${apiUrl}advisory_suppliers/${id}/orders?page=${page}&status=${status}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Insurance") {
      try {
        const res = await axios.get(
          `${apiUrl}insurance_suppliers/${id}/orders?page=${page}&status=${status}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Mechanization") {
      try {
        const res = await axios.get(
          `${apiUrl}mechanization_suppliers/${id}/orders?page=${page}&status=${status}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Crop Input") {
      try {
        const res = await axios.get(
          `${apiUrl}input_suppliers/${id}/orders?page=${page}&status=${status}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    } else if (orderType === "Livestock Input") {
      try {
        const res = await axios.get(
          `${apiUrl}livestock_suppliers/${id}/orders?page=${page}&status=${status}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        // return custom error message from API if any
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response && error.response.data) {
          cogoToast.error("An error occurred!");
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.response.data);
        }
      }
    }
  }
);

export const requestRefund = createAsyncThunk(
  "requestRefund",
  async ({ value }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${finagApiUrl}refunds/`, value, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });

      if (res.status === 200 || res.status === 201) {
        cogoToast.success("Refund created successfully!");
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response && error.response.data) {
        if (error.response.data.error) {
          cogoToast.error(error.response.data.error);
        } else {
          cogoToast.error("An error occurred!");
        }

        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);
