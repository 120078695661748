import React,{useEffect, useState} from 'react';
import Header from '../../components/Header';
import { Clear, ExpandMore, ExpandLess, Refresh } from "@mui/icons-material";
import ModalComponent from '../../components/Modals/modal';
import { useDispatch, useSelector } from "react-redux";
import EmptyState from '../../components/EmptyState';
import warning from "../../assets/icons/warning.svg";
import { CancelInvite, DeactivateTeammate, getDepartments, getRolesByDepartment, getTeammatesByStatus, InviteTeammate, resendInviteLink } from '../../redux/Auth/authActions';
import { Form, Formik } from "formik";
import cogoToast from "cogo-toast";
import { inviteTeammatesValidator } from '../../validationSchema/validator';


const TeammatePage = () => {

    const [activeTab, setActiveTab] = useState("teammates");
    const [isShow, setShow] = useState(false);
    const [selectedRole, setSelectedRole] = useState({
        name: "",
        display_name: ""
    });
    const [selectedDepartment, setSelectedDepartment] = useState({
        id: "",
        name: ""
    });
    const [isRoleDrop, setIsRoleDrop] = useState(false);
    const [isDepartmentDrop, setIsDepartmentDrop] = useState(false);
    const [isCancelModal, setCancelModal] = useState(false);
    const [isDeactivateModal, setDeactivateModal] = useState(false);
    const [cancelInfo, setCancelInfo] = useState({
        email: "",
        role: "",
        department_id: "",
    });
    const [teammateEmail, setTeammateEmail] = useState("")
    const [loader, setLoader] = useState(false);
    const [cancelLoader, setCancelLoader] = useState(false);
    const [resendLoader, setResendLoader] = useState(false);
    const dispatch = useDispatch();
    const { loading, teammates, roles, departments } = useSelector((state) => state.auth);


    const getInitials = (name) => {
        if (!name) return ""; // Handle empty or undefined names
    
        const words = name.trim().split(/\s+/); // Split by spaces, removing extra spaces
        return words
            .slice(0, 2) // Take only the first two words
            .map(word => word[0].toUpperCase()) // Get the first letter of each word in uppercase
            .join(""); // Join them
    };

    const getEmailInitials = (email) => {
    const namePart = email.split("@")[0]; // Get the part before @
    return namePart.slice(0, 2).toUpperCase(); // Get first two letters and capitalize
    };

    const formatDepartmentName = (department) => {
        const departmentMap = {
            SALES: "Sales",
            CUSTOMER_SUPPORT: "Customer Support",
            PRODUCT: "Product",
            VENDORS: "Vendors",
            FIELD_AGENTS: "Field Agents",
            QUALITY_ASSURANCE: "Quality Assurance",
            BANKING: "Banking",
        };
        return departmentMap[department] || department.replace(/_/g, " ").toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
    };

    const formatRoleName = (role) => {
        const roleMap = {
            CUSTOMER_SUPPORT_LEAD: "Customer Support Lead",
            CUSTOMER_SUPPORT: "Customer Support",
            SALES_AGENT: "Sales Agent",
            SALES_AGENT_LEAD: "Sales Agent Lead",
            SALES_AGENT_ADMIN: "Sales Agent Admin",
            CEO: "CEO",
            CFO: "CFO",
            CTO: "CTO",
            COO: "COO",
            PRODUCT_MANAGER: "Product Manager",
            VENDOR: "Vendor",
            FARMER: "Farmer",
            GATHER_AGENT: "Gather Agent",
            FARMER_AND_GATHER_AGENT: "Farmer and Gather Agent",
            TESTER: "Tester",
            FCMB_ADMIN: "Fcmb Admin",
        };
        return roleMap[role] || role.replace(/_/g, " ").toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
    };
    
    
    
    const handleRoleDropdown = () => {
        if(selectedDepartment && selectedDepartment.id !== ""){
            setIsRoleDrop(!isRoleDrop);
        }
        else{
            cogoToast.error("Kindly select a department first");
        }
    };

    const handleDepartmentDropdown = () => {
        setIsDepartmentDrop(!isDepartmentDrop);
    };

    const handleClickRole = (roleName) => {
            setIsRoleDrop(!isRoleDrop);
            setSelectedRole(roleName);
    };

    const handleClickDepartment = (departmentName) => {
        setIsDepartmentDrop(!isDepartmentDrop);
        setSelectedDepartment(departmentName);

        // clear existing role selected
        setSelectedRole({
            name: "",
            display_name: ""
        });

        // fetch roles based on department
        dispatch(getRolesByDepartment({ id: departmentName.id }));
    };


      const handleSubmit = (values) => {
        let res = {
            ...values,
            role: selectedRole.name,
          };
        setLoader(true);
        dispatch(
          InviteTeammate({
            email: res.email,
            role: res.role,
            department_id: selectedDepartment.id,
          })
        ).then((res) => {
          if (res?.type === "InviteTeammate/fulfilled") {
            setLoader(false);
            setShow(false)
            setActiveTab("pending")
          } else {
            setLoader(false);
          }
        });
      };

      const toggleCancelInvite = (val) => {
        setCancelInfo(
            {
                email: val.email,
                role: val.roles[0].name,
                department_id: val.roles[0].department.id,
            }
        );
        setCancelModal(!isCancelModal)
      }

      const toggleDeactivateModal = (val) =>{
         setTeammateEmail(val.email)
        setDeactivateModal(!isDeactivateModal)
      }

      const handleCancelInvite = () => {
        setCancelLoader(true);
        dispatch(CancelInvite({
            email: cancelInfo.email,
            role: cancelInfo.role,
            department_id: cancelInfo.department_id,
        })).then((res) => {
            if (res?.type === "CancelInvite/fulfilled") {
              setCancelLoader(false);
              setCancelModal(!isCancelModal)
              
              dispatch(getTeammatesByStatus({
                status: activeTab === 'pending',
                page: 1,
              }))
            }
            else {
            setCancelLoader(false);
            }
          });
      }

      const handleDeactivateTeammate = () =>{
        setCancelLoader(true);
        dispatch(DeactivateTeammate({
            email: teammateEmail
        })).then((res) => {
            if (res?.type === "DeactivateTeammate/fulfilled") {
              setCancelLoader(false);
              setDeactivateModal(!isDeactivateModal)
              
              dispatch(getTeammatesByStatus({
                status: activeTab === 'teammates',
                page: 1,
              }))
            }
            else {
            setCancelLoader(false);
            }
          });
      }

      const handleResendLink = (val) =>{
        setResendLoader(true)
        dispatch(resendInviteLink({
             email: val.email,
            role: val.roles[0].name,
            department_id: val.roles[0].department.id,
        })).then((res) => {
            if (res?.type === "resendInviteLink/fulfilled") {
              setResendLoader(false);
            } else {
                setResendLoader(false);
            }
          });
    }


    useEffect(()=>{
        dispatch(getTeammatesByStatus({
            status: activeTab === 'teammates'? 'active' : 'pending',
            page: 1,
        }))
        dispatch(getDepartments())
    },[dispatch, activeTab])


    return (  
        <>

        {/* Invite Modal */}
        <ModalComponent
        title="Invite teammate"
        show={isShow}
        showModal={() => setShow(false)}
      >
        <div className="mt-6 w-full">    
            <Formik
                onSubmit={(values, { setSubmitting }) =>
                handleSubmit(values, setSubmitting)
                }
                validationSchema={inviteTeammatesValidator}
                initialValues={{
                    email: "",
                }}
            >
                {({
                handleChange,
                isSubmitting,
                handleSubmit,
                handleBlur,
                values,
                touched,
                errors,
                dirty,
                isValid
                }) => (
          <Form onSubmit={handleSubmit}>
          <div>
            <div className="mb-4">
              <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                Email Address
              </p>
              <input
                type="email"
                name="email"
                className={
                    touched.email && errors.email
                      ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-sm focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] mt-1 bg-white py-3 px-4"
                      : "appearance-none border bg-white placeholder:text-[#96A397] border-[#96A397] focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-[8px] font-medium text-sm leading-5 text-[#344335] py-3 px-4 mt-1 w-full"
                  }
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                placeholder="Teammates’s email address"
              />
               {touched.email && errors.email ? (
                <div className="flex">
                <img src={warning} className="" alt="warning" />
                <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                    {touched.email && errors.email}
                </small>
                </div>
            ) : null}
            </div>

            <div className='grid grid-cols-2 gap-3'>
            <div className="relative">
                <label className="font-[400] text-sm leading-[18px] text-[#344335]">
                    Department
                </label>
                <div
                    className="flex items-center  cursor-pointer justify-between outline-0 h-[46px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5"
                    onClick={handleDepartmentDropdown}
                >
                    <p className={`${selectedDepartment.name ? 'text-[#344335]' : 'text-[#96A397]'}`}>{selectedDepartment && selectedDepartment.name ? formatDepartmentName(selectedDepartment.name) : "Select department"}</p>
                    {isDepartmentDrop ? (
                    <ExpandLess
                        className="text-[#ABB6AC] cursor-pointer"
                        style={{ fontSize: "18px" }}
                    />
                    ) : (
                    <ExpandMore
                        className="text-[#ABB6AC] cursor-pointer"
                        style={{ fontSize: "18px" }}
                    />
                    )}
                </div>
                {isDepartmentDrop && (
                    <div
                    className="absolute top-20 left-0 rounded-[8px] p-[8px] w-full bg-white z-10 max-h-[200px] overflow-y-scroll"
                    style={{
                        boxShadow:
                        "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                    }}
                    >
                    {departments?.map((data, index) => {
                        return (
                        <div
                        key={index}
                        className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                        onClick={() => handleClickDepartment(data)}
                    >
                        <p className="font-medium text-sm leading-5 text-[#344335]">
                        {formatDepartmentName(data.name)}
                        </p>
                    </div>
                    );
                })}
                </div>
               )}
            </div>

            <div className="relative">
                <label className="font-[400] text-sm leading-[18px] text-[#344335]">
                    Role
                </label>
                <div
                    className="flex items-center  cursor-pointer justify-between outline-0 h-[46px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5"
                    onClick={handleRoleDropdown}
                >
                    <p className={`${selectedRole.display_name ? 'text-[#344335]' : 'text-[#96A397]'}`}>{selectedRole && selectedRole.display_name ? formatRoleName(selectedRole.display_name) : "Select Role"}</p>
                    {isRoleDrop ? (
                    <ExpandLess
                        className="text-[#ABB6AC] cursor-pointer"
                        style={{ fontSize: "18px" }}
                    />
                    ) : (
                    <ExpandMore
                        className="text-[#ABB6AC] cursor-pointer"
                        style={{ fontSize: "18px" }}
                    />
                    )}
                </div>
                {isRoleDrop && (
                    <div
                    className="absolute top-20 left-0 rounded-[8px] p-[8px] w-full bg-white z-10 max-h-[200px] overflow-y-scroll"
                    style={{
                        boxShadow:
                        "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                    }}
                    >
                    {roles?.map((data, index) => {
                        return (
                        <div
                        key={index}
                        className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                        onClick={() => handleClickRole(data)}
                    >
                        <p className="font-medium text-sm leading-5 text-[#344335]">
                        {formatRoleName(data.display_name)}
                        </p>
                    </div>
                    );
                })}
                </div>
               )}
            </div>

            </div>

            

            <div className='mt-6'>
                <button
                    type="submit"
                    disabled={
                        !(dirty && isValid) || loader || selectedRole?.name === "" || selectedDepartment?.id === ""
                      }
                    className="rounded-lg px-6 disabled:bg-[#ABB6AC]  py-4 w-full bg-[#2B8C34] text-[#FFFFFF] text-sm font-medium transition-all"
                >
                    Invite Teammate
                </button> 
            </div>
         
          </div>
             </Form>
                )}
         </Formik>
        </div>
      </ModalComponent>

      {/* Cancel Modal */}
      <ModalComponent
        show={isCancelModal}
        showModal={() => setCancelModal(false)}
        title="Cancel Invitation"
        subtitle=""
      >
        <div>
            <div className='mt-6'>
                <p className='text-[#4B564D] font-medium text-sm'>Are you sure you want to cancel the invite sent to:  <span className='font-semibold text-[#181B19]'>{cancelInfo ? cancelInfo.email: ''}?</span></p>
            </div>

            <div className="flex gap-2 justify-end mt-8 mb-3">
                <button
                    onClick={() => setCancelModal(false)}
                    type="submit"
                    className="bg-transparent
                            rounded-lg px-6 py-3 text-[#5C715E] text-sm font-medium transition-all"
                >
                    Close
                </button>
                <button
                    onClick={handleCancelInvite}
                    disabled={cancelLoader}
                    type="submit"
                    className="bg-[#B92043] 
                            disabled:bg-[#ABB6AC] 
                            rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#B92043] transition-all"
                >
                    Cancel Invite
                </button>
            </div>
        </div>
    
      </ModalComponent>


      {/* Deactivate Modal */}
      <ModalComponent
        show={isDeactivateModal}
        showModal={() => setDeactivateModal(false)}
        title="Deactivate Teammate"
        subtitle=""
      >
        <div>
            <div className='mt-6'>
                <p className='text-[#4B564D] font-medium text-sm'>Are you sure you want to deactivate <span className='font-semibold text-[#181B19]'>{teammateEmail ? teammateEmail: 'testuser@example.com'}?</span></p>
            </div>

            <div className="flex gap-2 justify-end mt-8 mb-3">
                <button
                    onClick={() => setDeactivateModal(false)}
                    type="submit"
                    className="bg-transparent
                            rounded-lg px-6 py-3 text-[#5C715E] text-sm font-medium transition-all"
                >
                    No
                </button>
                <button
                    onClick={handleDeactivateTeammate}
                    disabled={cancelLoader}
                    type="submit"
                    className="bg-[#B92043] 
                            disabled:bg-[#ABB6AC] 
                            rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#B92043] transition-all"
                >
                    Yes, proceed
                </button>
            </div>
        </div>
    
      </ModalComponent>

        <Header text="Teammate Management">
        <div>
          <button
          onClick={() => setShow(true)}
            className="w-full py-3 flex items-center justify-center px-4 hover:bg-opacity-[0.8] bg-[#EDF7EE] rounded-[8px] text-sm font-medium transition-all"
          >
            <p className="text-[#2B8C34] font-medium text-sm">
              Invite Teammate
            </p>
          </button>
        </div>
       </Header>

       <div className='mt-5 bg-white rounded-[8px] border border-[#E1E6E1]'>
            <div className='flex py-4 px-4 justify-between items-center border-b border-[#E1E6E1]'>
                <div>
                    <p className='text-[#4B564D] font-semibold text-sm'>Invited Teammates</p>
                </div>
                <div className="flex items-center border border-[#E1E6E1] bg-[#F6F7F6] rounded-lg overflow-hidden w-fit">
                <button
                    className={`px-4 py-2 text-sm font-semibold ${
                    activeTab === "teammates"
                        ? "bg-white text-[#161D17]"
                        : "text-[#5F6D60]"
                    } rounded-lg transition`}
                    onClick={() => setActiveTab("teammates")}
                >
                    Teammates
                </button>
                <button
                    className={`px-4 py-2 text-sm font-semibold ${
                    activeTab === "pending"
                        ? "bg-white text-[#161D17]"
                        : "text-[#5F6D60]"
                    } rounded-lg transition`}
                    onClick={() => setActiveTab("pending")}
                >
                    Pending Invitation
                </button>
                </div>
            </div>
            <div className='py-4 px-4'>
                {/* Teammates tables */}
               {activeTab === "teammates" && 
                    <>
                      {loading ? (
                            <div className="animate-pulse w-full mt-4 mb-4 px-4 pb-4">
                            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                            <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
                            </div>
                        )
                        :
                        (
                            <>
                            {teammates.length === 0 ? (
                                <EmptyState text="Your teammate will appear here once added!" />
                            ) : (
                             <div className="overflow-x-auto relative">
                                <table className="w-full text-left">
                                    <thead className="rounded-lg bg-[#F6F7F6] text-sm border border-[#E1E6E1]">
                                    <tr>
                                        <th scope="col" className="px-4 py-4 text-[#5F6D60] font-semibold ">
                                        Name
                                        </th>
                                        <th scope="col" className="px-4 py-4 font-semibold text-[#5F6D60]">
                                        Email Address
                                        </th>
                                        <th scope="col" className="px-4 py-4 font-semibold text-[#5F6D60]">
                                        Department
                                        </th>
                                        <th scope="col" className="px-4 py-4 font-semibold text-[#5F6D60]">
                                        Role
                                        </th>
                                        <th scope="col" className="px-4 py-4 font-semibold text-[#5F6D60]">
                                        
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="text-[#4B564D] text-sm border-[#E1E6E1]">
                                    {teammates.map((data, index) => {
                                                return (
                                        <tr 
                                            key={index}
                                            className={`bg-white text-sm ${index === teammates.length - 1 ? '': 'border-b border-[#E1E6E1]'}`}
                                            >
                                            <th
                                                scope="row"
                                                className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                                            >
                                            <div className='flex gap-2 items-center'>
                                                <div className='bg-[#2B8C34] text-[#FFFFFF] text-xs font-medium w-[32px] h-[32px] flex justify-center items-center rounded-full'>
                                                    {data && data.name !==" " ? getInitials(data.name) : 'TU'}
                                                </div>
                                                <div>
                                                    <p className='text-sm text-[#2E332F] font-semibold'>{data && data.name !== " " ? data.name : 'Test User'}</p>
                                                </div>
                                            </div>
                                            </th>
                                            <td className="py-4 px-4 text-[#5F6D60] font-semibold">
                                                {data && data.email ? data.email: 'testuser@example.com'}
                                            </td>
                                            <td className="py-4 px-4 text-[#5C715E]">
                                            <div className='bg-[#DBFFDF] rounded-[28px] text-[#2B8C34] text-sm font-semibold py-1 px-2 w-fit'>
                                                    
                                                    {data.roles?.length ? (data.roles[0].department ? formatDepartmentName(data.roles[0].department.name): 'Customer Support') : 'Customer Support'}
                                            </div>
                                            </td>
                                            <td className="py-4 px-4 text-[#5C715E]">
                                                <div className='flex gap-1 items-center'>
                                                    <div>
                                                        <p className='text-[#2E332F] font-semibold text-sm'>
                                                        {data.roles?.length ? formatRoleName(data.roles[0].name): 'Member'}
                                                        </p>
                                                    </div>
                                                    <div className='cursor-pointer'>
                                                        <ExpandMore style={{ color: "#5F6D60", fontSize: "18px" }} />
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="py-4 px-4 cursor-pointer"  onClick={() => toggleDeactivateModal(data)}>
                                                <Clear style={{ fontSize: "16px", color: '#5F6D60' }} />    
                                            </td>
                                        </tr>
                                        )})}
                                    </tbody>
                                </table>
                            </div>
                            )}
                        </>
                        )}
                    </>
                }
                
                {/* Pending Invite table */}
                {activeTab === "pending" && 
                  <>
                    {loading ? (
                        <div className="animate-pulse w-full mt-4 mb-4 px-4 pb-4">
                        <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                        <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                        <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                        <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                        <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                        <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
                        </div>
                    )
                    :
                    (
                        <>
                        {teammates.length === 0 ? (
                            <EmptyState text="You have no pending teammate invitation!" />
                        ) : (
                        <div className="overflow-x-auto relative">
                            <table className="w-full text-left">
                            <thead className="rounded-lg bg-[#F6F7F6] text-sm border border-[#E1E6E1]">
                                <tr>
                                    <th scope="col" className="px-4 py-4 font-semibold text-[#5F6D60]">
                                    Email Address
                                    </th>
                                    <th scope="col" className="px-4 py-4 font-semibold text-[#5F6D60]">
                                    Department
                                    </th>
                                    <th scope="col" className="px-4 py-4 font-semibold text-[#5F6D60]">
                                    Role
                                    </th>
                                    <th scope="col" className="px-4 py-4 font-semibold text-[#5F6D60]">
                                    
                                    </th>
                                   
                                </tr>
                              </thead>
                                <tbody className="text-[#4B564D] text-sm border-[#E1E6E1]">
                                {teammates.map((data, index) => {
                                            return (
                                  <tr
                                        key={index}
                                        className="bg-white text-sm"
                                        >
                                        <th
                                            scope="row"
                                            className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                                        >
                                        <div className='flex gap-2 items-center'>
                                            <div className='bg-[#2B8C34] text-[#FFFFFF] text-xs font-medium w-[32px] h-[32px] flex justify-center items-center rounded-full'>
                                            {data?.email ? getEmailInitials(data.email) : 'TU'}
                                            </div>
                                            <div>
                                                <p className='text-sm text-[#2E332F] font-semibold'>
                                                {data && data.email ? data.email: 'testuser@example.com'}
                                                </p>
                                            </div>
                                        </div>
                                        </th>   
                                        <td className="py-4 px-4 text-[#5C715E]">
                                        <div className='bg-[#DBFFDF] rounded-[28px] text-[#2B8C34] text-sm font-semibold py-1 px-2 w-fit'>
                                           {data.roles?.length ? (data.roles[0].department ? formatDepartmentName(data.roles[0].department.name): 'Customer Support') : 'Customer Support'}
                                        </div>
                                        </td>
                                        <td className="py-4 px-4 text-[#5C715E]">
                                            <div className='flex gap-1 items-center'>
                                                <div>
                                                    <p className='text-[#2E332F] font-semibold text-sm'>
                                                    {data.roles?.length ? formatRoleName(data.roles[0].name): 'Member'}
                                                    </p>
                                                </div>
                                                <div className='cursor-pointer'>
                                                    <ExpandMore style={{ color: "#5F6D60", fontSize: "18px" }} />
                                                </div>
                                            </div>
                                        </td>
                                        <td 
                                        className="py-4 px-4">
                                            <div className='flex gap-4'>
                                                <div className='cursor-pointer' onClick={() => toggleCancelInvite(data)}                                                >
                                                     <Clear style={{ fontSize: "16px", color: '#5F6D60' }} />    
                                                </div>
                                                <button onClick={() => handleResendLink(data)} disabled={resendLoader} className='flex gap-1 disabled:opacity-[0.7] items-center'>
                                                    <p className='text-[#2B8C34] font-medium text-sm'>Resend Invite</p>
                                                    <Refresh style={{ fontSize: "16px", color: '#2B8C34' }} />  
                                                </button>
                                            </div>
                                           
                                        </td>
                                      
                                    </tr>
                                )})}
                                </tbody>
                            </table>
                        </div>
                        )}
                    </>
                    )}
                    </> 
                }
            </div>

       </div>

     </>
    );
}
 
export default TeammatePage;